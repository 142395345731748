import React, { Component } from 'react';
import axios from 'axios';
import Modal from '../../../components/UI/Modal/Modal';
import Spinner from '../../../components/UI/Spinner/Spinner';
import InsertContract from './InsertContract';

class Contracts extends Component {

  state = {
    contracts: [],
    activecontracts: [],
    contracttype: null,
    loading: false,
    companysort: '',
    editcompanymodal: false,
    insertcontract: false
  }

  editCancelledHandler = () => {
    this.setState({ editcompanymodal: false }); 
    this.setState({ insertcontract: false }); 
  }

  editmodal = value => {
    this.setState({ editcompanymodal: true }); 
  }

  openinsertmodal = value => {
    this.setState({ insertcontract: value }); 
  }

  fetchactivecontracts = (value) => {
       
      this.setState( { loading: true } );

       const order = {
           contype: value       
       }
       
       axios.post( 'https://backend.24rent.fi/api2/contract/activebytype', order)
           .then( response => response.data)
           .then((data) => {
             this.setState({ activecontracts: data })
             console.log(this.state.activecontracts)
             this.setState( { loading: false} ); 
            })
       
   }

  changetype = (value) => {
      this.setState({contracttype : value});
          if (value){
              this.fetchactivecontracts(value);
          } else {
              this.setState({companysort : value});
          }
  }

  changecompany = (value) => {
      this.setState({companysort : value});
      console.log(this.state.companysort)
  }

  componentDidMount() {
    this.setState( { loading: true} ); 
    const url = 'https://backend.24rent.fi/api2/contract/topview'
    axios.get(url).then(response => response.data)
    .then((data) => {
      this.setState({ contracts: data })
      console.log(this.state.contracts)
      this.setState( { loading: false} ); 
     })
  }

  render() {
      let grid = (
        <React.Fragment>
        <h1>Contracts Top View</h1>
        
        <table>
        <tbody>
        <tr>
            <th>Name</th>
            <th>Count</th>
        </tr>

        {this.state.contracts.map((contract) => (
        <tr key={contract.id}>
         <td style={{ color: 'blue' }} onClick={() => this.changetype(contract.typeid)}><u>{ contract.name }</u></td>
            <td>{ contract.count }</td>
        </tr>
        ))}
        </tbody>
        </table>
        </React.Fragment>
      );
      if (this.state.contracttype) {
      grid = (
        <div>
        Active &nbsp;
        {this.state.contracts.filter(type => type.typeid === this.state.contracttype).map(filter => (
             <div key={filter.typeid} style={{ display: 'inline-block' }}>
             {filter.name}
             </div>
            ))}
        &nbsp; contracts

                <div>
                    <table>
                        <tbody>
                          <tr>
                           <td bgcolor='cccccc' onClick={() => this.changetype(null)}>go back</td>
                          </tr>
                        </tbody>
                    </table>
                </div>

                <table>
                <tbody>
                <tr>
                    <th>Name</th>
                    <th>Company Name</th>
                    <th>Cost Monthly</th>
                    <th>Max cars</th>
                    <th>Cars</th>
                    <th>Address</th>
                </tr>
        
                {this.state.activecontracts.map((acontract, index) => (
                <tr key={Date.now()+index}>
                    <td>{ acontract.contractname }</td>
                    <td style={{ color: 'blue' }} onClick={() => this.changecompany(acontract.companyname)}><u>{ acontract.companyname }</u></td>
                    <td>{ acontract.cost_monthly }</td>
                    <td>{ acontract.max_cars }</td>
                    <td>{ acontract.cars }</td>
                    <td>{ acontract.address }</td>
                </tr>
                

                ))}
                </tbody>
                </table>
                <div>
                    <table>
                        <tbody>
                          <tr>
                            <td>
                            <button onClick={() => this.openinsertmodal(true)}>
                               Insert new this type contract
                            </button>       
                               <Modal show={this.state.insertcontract} modalClosed={this.editCancelledHandler}>
                               <InsertContract onEditChange={this.openinsertmodal} ctype={this.state.contracttype}/>
                               </Modal>  
                            </td>
                          </tr>
                          <tr>
                           <td bgcolor='cccccc' onClick={() => this.changetype(null)}>go back</td>
                          </tr>
                        </tbody>
                    </table>
                </div>

                </div>
     
      )
      }
      if (this.state.contracttype && this.state.companysort) {
        grid = (
          <div>
          Active &nbsp;
          {this.state.contracts.filter(type => type.typeid === this.state.contracttype).map(filter => (
               <div key={filter.typeid} style={{ display: 'inline-block' }}>
               {filter.name}
               </div>
              ))}
           &nbsp; contracts &nbsp;
  
               <div key={this.state.companysort} style={{ display: 'inline-block' }}>
               {this.state.companysort} 
               </div>
               <div  style={{ display: 'inline-block' }}>
               <button onClick={() => this.editmodal(true)}>Edit company</button>
               </div>
               <Modal show={this.state.editcompanymodal} modalClosed={this.editCancelledHandler}>
                  Here comes editcompany modal
               </Modal>  
             
  
                  <div>
                      <table>
                          <tbody>
                            <tr>
                             <td bgcolor='cccccc' onClick={() => this.changetype(null)}>go back</td>
                            </tr>
                          </tbody>
                      </table>
                  </div>
  
                  <table>
                  <tbody>
                  <tr>
                      <th>Name</th>
                      <th>Company Name</th>
                      <th>Cost Monthly</th>
                      <th>Max cars</th>
                      <th>Cars</th>
                      <th>Address</th>
                  </tr>
          
                  {this.state.activecontracts.filter(type => type.companyname === this.state.companysort).map((acontract, index) => (
                  <tr key={Date.now()+index}>
                      <td>{ acontract.contractname }</td>
                      <td style={{ color: 'blue' }} onClick={() => this.changecompany(acontract.companyname)}><u>{ acontract.companyname }</u></td>
                      <td>{ acontract.cost_monthly }</td>
                      <td>{ acontract.max_cars }</td>
                      <td>{ acontract.cars }</td>
                      <td>{ acontract.address }</td>
                  </tr>
                  
  
                  ))}
                  </tbody>
                  </table>
                  <div>
                      <table>
                          <tbody>
                            <tr>
                             <td bgcolor='cccccc' onClick={() => this.changetype(null)}>go back</td>
                            </tr>
                          </tbody>
                      </table>
                  </div>
  
                  </div>
       
        )
        }
    if (this.state.loading) {
        grid = (
          <Spinner />
      )
      }
    return (
     <div>{grid}</div>
        
        
    );
  }

}

export default Contracts;