import React, { Component } from 'react';
import axios from 'axios';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { connect } from 'react-redux';

class editContract extends Component {
    state = {
        loading: false,
        contract: this.props.contract,
        error: false
    }

    orderHandler = ( event ) => {
        event.preventDefault();
        this.setState( { loading: true } );

        const order = {
            contract: this.state.contract,
            token: this.props.token
        }
        
        axios.post( 'https://backend.24rent.fi/api2/contract/update', order)
            .then( response => {
                this.setState( { loading: false } );
                this.props.onEditChange(null);
            } )
            .catch( error => {
                if (error.response) {
                    console.log(error.response);
                  }
                this.setState( { loading: false, error : error } );
            
            } );
    }

    inputChangedHandler = (event, inputIdentifier) => {

       const updatedContract = {
           ...this.state.contract
       };

       updatedContract[inputIdentifier] = event.target.value;
       this.setState({contract: updatedContract});
    }

    updateInput = (event) => {
   
        this.setProps({editcontract : null});
        }

    render () {
        let form = (
            <form onSubmit={this.orderHandler}>
            {Object.keys(this.state.contract).map(coKey =>
                (
                    <div key={coKey + this.state.contract['id'] + 'a'}>
                    <div key={coKey + this.state.contract['id']}  style={{ display: 'inline-block', width: '200px'}}>
                    <label>{coKey}</label>
                    </div><div style={{ display: 'inline-block'}}>
                    <input 
                        name={coKey}
                        id={coKey + this.state.contract['id']}
                        key={coKey + this.state.contract['id']}
                        value={this.state.contract[coKey] || ''}
                        onChange={(event) => this.inputChangedHandler(event, coKey)} />
                    </div>
                    </div>
                )
                )}

                <button>UPDATE</button>
                <button type="button" onClick={(event) => this.props.onEditChange(null)}>CANCEL</button>
            </form>
        );
        if ( this.state.loading ) {
           form = <Spinner />;
        }
        if ( this.state.error ) {
            form = this.state.error.message;
         }
        return (
            <div>
                <h4>Edit contract Data</h4>
                {form}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        islogged: state.token !== null,
        token: state.token
    }
};

export default connect(mapStateToProps)(editContract);