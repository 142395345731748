import React, { Component } from 'react';
import axios from 'axios';
import Spinner from '../../../components/UI/Spinner/Spinner';
// import Input from '../../../components/UI/Input/Input';
import { connect } from 'react-redux';

class activateContract extends Component {
    state = {
        insertForm: {
            name: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Name'
                },
                value: ''
            },
            description: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Description'
                },
                value: ''
            },
            contract_startdate: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'startdate like 2019-01-21'
                },
                value: ''
            },
            contract_enddate: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'enddate like 2020-04-30'
                },
                value: ''
            }, 
            id_contract_type: {
                elementType: 'select',
                elementConfig: {
                    options: [
                        {value: '7', displayValue: 'Parking, not free'},
                        {value: '6', displayValue: 'Real Estate Company'},
                        {value: '5', displayValue: 'Microleasing'},
                        {value: '4', displayValue: 'Housing Company'},
                        {value: '3', displayValue: 'Quota'},
                        {value: '2', displayValue: 'Minileasing'},
                        {value: '1', displayValue: 'Parking'}
                    ]
                },
                value: ''
            },
            cost_monthly: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'monthly cost 0.00'
                },
                value: ''
            },
            cost_initial: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'initial cost 0.00'
                },
                value: ''
            },
            income_monthly: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'monthly income 0.00'
                },
                value: ''
            },
            income_initial: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'initial income 0.00'
                },
                value: ''
            }, 
            metabase_url: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'https://https://metabase.24rent.fi/'
                },
                value: ''
            }, 
            metabase_public_url: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'https://metabase.24rent.fi/public'
                },
                value: ''
            },
            max_parking_slots: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'max parking slots in number'
                },
                value: ''
            },   
            /*        
            enabled: {
                elementType: 'select',
                elementConfig: {
                    options: [
                        {value: '1', displayValue: 'Enabled'},
                        {value: '0', displayValue: 'Disabled'}
                    ]
                },
                value: '1'
            }
            */
        },
        loading: false,
        contract: this.props.contract,
        error: false,
        phase: 0,
        companies: [],
        company: 1,
        contracttypes: [
            {value: '7', displayValue: 'Parking, not free'},
            {value: '6', displayValue: 'Real Estate Company'},
            {value: '5', displayValue: 'Microleasing'},
            {value: '4', displayValue: 'Housing Company'},
            {value: '3', displayValue: 'Quota'},
            {value: '2', displayValue: 'Minileasing'},
            {value: '1', displayValue: 'Parking'},
    //        {value: 'null', displayValue: 'Disabled'}
        ],
        contracttype: '1',
        cocontracts: [],
        contracts: [],
        linkcontract: [],
        customer : [],
        customerid : ''
    }

    componentDidMount() {
        const url = 'https://backend.24rent.fi/api2/company/getall'
        axios.get(url).then(response => response.data)
        .then((data) => {
          this.setState({ companies: data })
          console.log(this.state.companies)
        });
         const url2 = 'https://backend.24rent.fi/api2/cucontract/getall'
         axios.get(url2).then(response => response.data)
         .then((data) => {
           this.setState({ cucontracts: data })
           console.log(this.state.cucontracts)
        });
        const url3 = 'https://backend.24rent.fi/api2/contract/getall'
        axios.get(url3).then(response => response.data)
        .then((data) => {
          this.setState({ contracts: data })
          console.log(this.state.contracts)
        });    
      }

    orderHandler = ( event ) => {
     //   alert("Not implemented!")
        event.preventDefault();
        
        this.setState( { loading: true } );

        const order = {
            linktype: this.state.linktype,
            links: this.state.linkcontract,
            token: this.props.token,
            company: this.state.company,
            customer: this.state.customerid
        }
        
        axios.post( 'https://backend.24rent.fi/api2/contract/activate', order)
            .then( response => {
                this.setState( { loading: false } );
                this.props.onEditChange(false);
                this.setState( { phase: 0 } );
            } )
            .catch( error => {
                if (error.response) {
                    console.log(error.response);
                  }
                this.setState( { loading: false, error : error } );
            } );
        
    }

    inputChangedHandler = (event, inputIdentifier) => {
        const updatedInsertForm = {
            ...this.state.insertForm
        };
        const updatedFormElement = { 
            ...updatedInsertForm[inputIdentifier]
        };
        updatedFormElement.value = event.target.value;
        updatedInsertForm[inputIdentifier] = updatedFormElement;
        this.setState({insertForm: updatedInsertForm});
    }

    getCustomer = (event) => {
        const askcustomer = {
        //    customerid: event.target.value,
            customerid: this.state.customerid,
            token: this.props.token
        }
        
        axios.post( 'https://backend.24rent.fi/api2/customer/get', askcustomer)
            .then( response => response.data)
            .then((data) => {
                this.setState({ customer: data })
                console.log(this.state.customer)
              })
              .catch( error => {
                  if (error.response) {
                      console.log(error.response);
                    }
                  this.setState( { loading: false, error : error } );
              } );

        }

    updateInput = (event) => {
        this.setProps({editcontract : null});
        }

    inputCustomer = (event) => {
        this.setState({customerid : event.target.value});
        this.setState({company : ''});      
        }
    
    change = (event) => {
        this.setState({company : event.target.value});
        this.setState({customerid : ''});       
        }

    changetype = (event) => {
        this.setState({contracttype : event.target.value});
        }
    
    changelink = (event) => {
        this.setState({linkcontract : Array.from(event.target.selectedOptions, (item) => item.value)});
        }

    phaseChangedHandler = ( action ) => {
            switch ( action ) {
                case 'inc':
                    this.setState( ( prevState ) => { return { phase: prevState.phase + 1 } } )
                    break;
                case 'dec':
                    this.setState( ( prevState ) => { return { phase: prevState.phase - 1 } } )
                    break;
                case 'b2c':
                    this.setState( ( prevState ) => { return { phase: 10 } } )
                    break;
                case 'zero':
                    this.setState( ( prevState ) => { return { phase: 0 } } )
                    break;                        
                default:
                    break;
            }
        }

    render () {
        let linkki;
        const linkContractArray = [];
        for (let key in this.state.cucontracts) {
            linkContractArray.push(
                this.state.cucontracts[key].id_contract
            );
        }
        const formElementsArray = [];
        for (let key in this.state.insertForm) {
            formElementsArray.push({
                id: key,
                config: this.state.insertForm[key]
            });
        }
        let form = (
            <div style={{ height: '100px' }}>
            <form>
                <div style={{ display: 'table', width: '100%' }}>
                    <div style={{ verticalAlign: 'top' }}>
                        Are we going to activate B2B or B2C contract ?
                        <p></p>
                    </div>
                    <div style={{ position: 'absolute' }}>
                <button type="button" onClick={(event) =>  this.phaseChangedHandler( 'inc' )}>B2B  &raquo;</button>
                <button type="button" onClick={(event) =>  this.phaseChangedHandler( 'b2c' )}>B2C &raquo;</button>
                <button type="button" onClick={(event) => this.props.onEditChange(false)}>CANCEL</button>
                    </div>
                </div>
            </form>
            </div>
        );
        if ( this.state.phase === 1 ) {
            form = (
                <form>        
                Customers, 
                please select one company:

                <div style={{ padding: '20px' }} key='custselect'>
                    <select id='company' name='company' onChange={this.change} value={this.state.company}> 

                    {this.state.companies.map((company) => (
              <option key={company.id} value={company.id}>{company.name}</option>
     ))}

                </select>
                </div>
                <button type="button" onClick={(event) => this.phaseChangedHandler( 'dec' )}>&laquo; PREV</button>
                <button type="button" onClick={(event) =>  this.phaseChangedHandler( 'inc' )}>NEXT  &raquo;</button>
                </form>  
        );
        } 
        if ( this.state.phase === 10 ) {
            form = (
                <form>        
                <div style={{ padding: '20px' }} key='b2ccustselect'>
                    <p>
                    B2C customer, enter customer number:
                    </p>
                <p>
                    {
                        (isNaN(this.state.customer.id)) ?
                        null
                        : this.state.customer.firstname + ' ' + this.state.customer.surname 
                    }
                </p>
                <p>
                    {
                        (isNaN(this.state.customer.id)) ?
                        null
                        : this.state.customer.email   
                    }
                </p>
                <input id='customer' name='customer' onChange={this.inputCustomer} value={this.state.customerid}></input>
                <button type="button" onClick={(event) =>  this.getCustomer(this.state.customerid)}>get customer data</button>
               <p></p>
                </div>


                <button type="button" onClick={(event) => this.phaseChangedHandler( 'zero' )}>&laquo; PREV</button>
                {
                        (isNaN(this.state.customer.id)) ?
                        null
                        : <button type="button" onClick={(event) =>  this.phaseChangedHandler( 'inc' )}>NEXT  &raquo;</button>
                }
                </form>  
        );
        } 
        if ( this.state.phase === 11 ) {
            form = (
                <form>        
                Contract type, 
                please select type:

                <div style={{ padding: '20px' }} key='typeselect'>
                    <select id='cotype' name='cotype' onChange={this.changetype} value={this.state.contracttype}> 

                    {this.state.contracttypes.map((type) => (
                    <option key={type.value} value={type.value}>{type.displayValue}</option>
                ))}

                </select>
                </div>
                <button type="button" onClick={(event) => this.phaseChangedHandler( 'dec' )}>&laquo; PREV</button>
                <button type="button" onClick={(event) => this.phaseChangedHandler( 'inc' )}>NEXT  &raquo;</button>
                </form>  
        );
        } 
        if ( this.state.phase === 12 ) {
            form = (
                <div>      
             <div>
                 Please select &nbsp;
                {this.state.contracttypes.filter(type => type.value === this.state.contracttype).map(filter => (
                     <div key={filter.value} style={{ display: 'inline-block' }}>
                     {filter.displayValue}
                     </div>
                    ))}

                &nbsp; contract you would like to link to &nbsp;

                <p>
                    {
                        (isNaN(this.state.customer.id)) ?
                        null
                        : this.state.customer.firstname + ' ' + this.state.customer.surname 
                    }
                </p>
                <p>
                    {
                        (isNaN(this.state.customer.id)) ?
                        null
                        : this.state.customer.email   
                    }
                </p>

                &nbsp; (contracts with link are marked with *) &nbsp;
                <div  style={{ padding: '20px' }}>

                <form>
                 <select multiple id='linkcontract' name='linkcontract' onChange={this.changelink} value={this.state.linkcontract}> 

                {this.state.contracts.filter(type => type.id_contract_type === Number(this.state.contracttype)).map(filter => (
                     <option key={filter.id} value={filter.id}>
                     {filter.name}
                     
                     {
                      (linkContractArray.includes(filter.id)) ?
                         '*' : null
                        
                     }
                     {linkki}
                     </option>
                    ))}
                 </select>
                 </form>
                 </div>
            </div>
            
            <button type="button" onClick={(event) => this.phaseChangedHandler( 'dec' )}>&laquo; PREV</button>
            <button type="button" onClick={(event) => this.phaseChangedHandler( 'inc' )}>NEXT  &raquo;</button>
            </div>
        );
        }
        let links = this.state.linkcontract.map(function (x) {  return parseInt(x, 10); });
        if ( this.state.phase === 13 ) {
            form = (
                <div>
                    <center>      
                <table>
                <tbody>
                <tr key='23qw'>
            <th> Activate contract ? </th>
            </tr>
            <tr key='1285w'>
            <th> Name: &nbsp;

            {this.state.contracts.filter(type => links.includes(type.id)).map(filter => (
                    <div key={filter.id + 'a'}  style={{ display: 'inline-block' }}>
                     {filter.name}
                     </div>
            ))}

            </th>
            </tr>
            <tr key='875w'>
            <th> Customer: &nbsp;
            <p>
                    {
                        (isNaN(this.state.customer.id)) ?
                        null
                        : this.state.customer.firstname + ' ' + this.state.customer.surname 
                    }
                </p>
                <p>
                    {
                        (isNaN(this.state.customer.id)) ?
                        null
                        : this.state.customer.email   
                    }
                </p>
                            
            </th>
            </tr>
            <tr key='879w'>
            <th> 
            <form onSubmit={this.orderHandler}>           
                  
                  <button>YES</button>
            </form>
            </th>
            </tr>
            </tbody>
            </table>
            </center>

            <button type="button" onClick={(event) => this.phaseChangedHandler( 'dec' )}>&laquo; PREV</button>
            </div>

        );
        }  
        if ( this.state.phase === 2 ) {
            form = (
                <form>        
                Contract type, 
                please select type:

                <div style={{ padding: '20px' }} key='typeselect'>
                    <select id='cotype' name='cotype' onChange={this.changetype} value={this.state.contracttype}> 

                    {this.state.contracttypes.map((type) => (
                    <option key={type.value} value={type.value}>{type.displayValue}</option>
                ))}

                </select>
                </div>
                <button type="button" onClick={(event) => this.phaseChangedHandler( 'dec' )}>&laquo; PREV</button>
                <button type="button" onClick={(event) => this.phaseChangedHandler( 'inc' )}>NEXT  &raquo;</button>
                </form>  
        );
        } 
        if ( this.state.phase === 3 ) {
            form = (
                <div>      
             <div>
                 Please select &nbsp;
                {this.state.contracttypes.filter(type => type.value === this.state.contracttype).map(filter => (
                     <div key={filter.value} style={{ display: 'inline-block' }}>
                     {filter.displayValue}
                     </div>
                    ))}

                &nbsp; contract you would like to link to &nbsp;
                {this.state.companies.filter(name => name.id === Number(this.state.company)).map(filter => (
                     <div key={filter.id}  style={{ display: 'inline-block' }}>
                     {filter.name}
                     </div>
                     ))}
                &nbsp; (contracts with link are marked with *) &nbsp;
                <div  style={{ padding: '20px' }}>

                <form>
                 <select multiple id='linkcontract' name='linkcontract' onChange={this.changelink} value={this.state.linkcontract}> 

                {this.state.contracts.filter(type => type.id_contract_type === Number(this.state.contracttype)).map(filter => (
                     <option key={filter.id} value={filter.id}>
                     {filter.name}
                     
                     {
                      (linkContractArray.includes(filter.id)) ?
                         '*' : null
                        
                     }
                     </option>
                    ))}
                 </select>
                 </form>
                 </div>
            </div>
            
            <button type="button" onClick={(event) => this.phaseChangedHandler( 'dec' )}>&laquo; PREV</button>
            <button type="button" onClick={(event) => this.phaseChangedHandler( 'inc' )}>NEXT  &raquo;</button>
            </div>
        );
        }
        if ( this.state.phase === 4 ) {
            form = (
                <div>
                    <center>     
                <table>
                <tbody>
                <tr key='123qw'>
            <th> Activate contract ? </th>
            </tr>
            <tr key='1285w'>
            <th> Name: &nbsp;

            {this.state.contracts.filter(type => links.includes(type.id)).map(filter => (
                    <div key={filter.id + 'a'}  style={{ display: 'inline-block' }}>
                     {filter.name}
                     </div>
            ))}

            </th>
            </tr>
            <tr key='8755w'>
            <th> Company: &nbsp;
                            {this.state.companies.filter(name => name.id === Number(this.state.company)).map(filter => (
                                <div key={filter.id}  style={{ display: 'inline-block' }}>
                                {filter.name}
                                </div>
                                ))}
                            
            </th>
            </tr>
            <tr key='8795w'>
            <th> 
            <form onSubmit={this.orderHandler}>           
                  
                  <button>YES</button>
            </form>
            </th>
            </tr>
            </tbody>
            </table>
            </center> 

            <button type="button" onClick={(event) => this.phaseChangedHandler( 'dec' )}>&laquo; PREV</button>
            </div>

        );
        }  
        if ( this.state.loading ) {
           form = <Spinner />;
        }
        if ( this.state.error ) {
            form = this.state.error.message;
         }
        return (
            <div>
                <h4>Activate contract</h4>
                {form}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        islogged: state.token !== null,
        token: state.token
    }
};

export default connect(mapStateToProps)(activateContract);