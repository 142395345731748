import React, { Component } from 'react';
import axios from 'axios';

// test push

class Companies extends Component {

  state = {
    companies: []
  }


  componentDidMount() {
    const url = 'https://backend.24rent.fi/api2/company/topview'
    axios.get(url).then(response => response.data)
    .then((data) => {
      this.setState({ companies: data })
      console.log(this.state.companies)
     })
  }

  render() {
    return (
        <React.Fragment>
        <h1>Customer Companies Top View</h1>
        <table>
        <tbody>
        <tr>
            <th>Name</th>
            <th>Active Contract</th>
            <th>Contract Type</th>
            <th>NOF</th>
        </tr>

        {this.state.companies.map((company) => (
        <tr key={company.id}>
            <td>{ company.name }</td>
            <td>{ company.active_contract }</td>
            <td>{ company.contract_type }</td>
            <td>{ company.nof }</td>
        </tr>
        ))}
        </tbody>
        </table>
        </React.Fragment>
    );
  }

}

export default Companies;