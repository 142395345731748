import React, { Component } from 'react';
import axios from 'axios';
import Spinner from '../UI/Spinner/Spinner';
import { connect } from 'react-redux';

class editCompany extends Component {
    state = {
        loading: false,
        company: this.props.company,
        error: false
    }

    orderHandler = ( event ) => {
        event.preventDefault();
        this.setState( { loading: true } );

        const order = {
            company: this.state.company,
            token: this.props.token
        }
        
        axios.post( 'https://backend.24rent.fi/api2/company/update', order)
            .then( response => {
                this.setState( { loading: false } );
                this.props.onEditChange(null)
               // this.props.history.push( '/' );
            } )
            .catch( error => {
                if (error.response) {
                    console.log(error.response);
                  }
                this.setState( { loading: false, error : error } );
            
            } );
    }

    inputChangedHandler = (event, inputIdentifier) => {

       const updatedCompany = {
           ...this.state.company
       };

       updatedCompany[inputIdentifier] = event.target.value;
       this.setState({company: updatedCompany});
    }

    updateInput = (event) => {
   
        this.setProps({editcompany : null});
        }

    render () {
        let form = (
            <form onSubmit={this.orderHandler}>
            {Object.keys(this.state.company).map(coKey =>
                (
                    <div key={coKey + this.state.company['id']}>
                    <label>{coKey}</label>
                    <input 
                        name={coKey}
                        id={coKey + this.state.company['id']}
                        key={coKey + this.state.company['id']}
                        value={this.state.company[coKey] || ''}
                        onChange={(event) => this.inputChangedHandler(event, coKey)} />
                    </div>
                )
                )}

                <button>UPDATE</button>
                <button type="button" onClick={(event) => this.props.onEditChange(null)}>CANCEL</button>
            </form>
        );
        if ( this.state.loading ) {
           form = <Spinner />;
        }
        if ( this.state.error ) {
            form = this.state.error.message;
         }
        return (
            <div>
                <h4>Edit company Data</h4>
                {form}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        islogged: state.token !== null,
        token: state.token
    }
};

export default connect(mapStateToProps)(editCompany);