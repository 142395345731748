import React, { Component } from 'react';
import axios from 'axios';
import Spinner from '../../../components/UI/Spinner/Spinner';
import Input from '../../../components/UI/Input/Input';
import { connect } from 'react-redux';

class insertContract extends Component {
    state = {
        insertForm: {
            name: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Name'
                },
                value: ''
            },
            description: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Description'
                },
                value: ''
            },
            contract_startdate: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'startdate like 2019-01-21'
                },
                value: ''
            },
            contract_enddate: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'enddate like 2020-04-30'
                },
                value: ''
            }, 
            id_contract_type: {
                elementType: 'select',
                elementConfig: {
                    options: [
                        {value: '7', displayValue: 'Parking, not free'},
                        {value: '6', displayValue: 'Real Estate Company'},
                        {value: '5', displayValue: 'Microleasing'},
                        {value: '4', displayValue: 'Housing Company'},
                        {value: '3', displayValue: 'Quota'},
                        {value: '2', displayValue: 'Minileasing'},
                        {value: '1', displayValue: 'Parking'}
                    ]
                },
                value: this.props.ctype
            },
            cost_monthly: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'monthly cost 0.00'
                },
                value: ''
            },
            cost_initial: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'initial cost 0.00'
                },
                value: ''
            },
            income_monthly: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'monthly income 0.00'
                },
                value: ''
            },
            income_initial: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'initial income 0.00'
                },
                value: ''
            }, 
            metabase_url: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'https://metabase.24rent.fi/'
                },
                value: ''
            }, 
            metabase_public_url: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'https://metabase.24rent.fi/public'
                },
                value: ''
            },
            max_parking_slots: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'max parking slots in number'
                },
                value: ''
            },          
            enabled: {
                elementType: 'select',
                elementConfig: {
                    options: [
                        {value: '1', displayValue: 'Enabled'},
                        {value: '0', displayValue: 'Disabled'}
                    ]
                },
                value: '1'
            }
        },
        loading: false,
        contract: this.props.contract,
        error: false,
  //      phase: 1,
        companies: [],
        company: null,
        contracttypes: [
            {value: '7', displayValue: 'Parking, not free'},
            {value: '6', displayValue: 'Real Estate Company'},
            {value: '5', displayValue: 'Microleasing'},
            {value: '4', displayValue: 'Housing Company'},
            {value: '3', displayValue: 'Quota'},
            {value: '2', displayValue: 'Minileasing'},
            {value: '1', displayValue: 'Parking'},
    //        {value: 'null', displayValue: 'Disabled'}
        ],
        contracttype: null
    }

    componentDidMount() {
        const url = 'https://backend.24rent.fi/api2/company/getall'
        axios.get(url).then(response => response.data)
        .then((data) => {
          this.setState({ companies: data })
          console.log(this.state.companies)
         })
      }

    orderHandler = ( event ) => {
        event.preventDefault();
        this.setState( { loading: true } );
        const formData = {};
        for (let formElementIdentifier in this.state.insertForm) {
            formData[formElementIdentifier] = this.state.insertForm[formElementIdentifier].value;
        }
        const order = {
            contract: formData,
            token: this.props.token
        }
        
        axios.post( 'https://backend.24rent.fi/api2/contract/insert', order)
            .then( response => {
                this.setState( { loading: false } );
                this.props.onEditChange(false)
            } )
            .catch( error => {
                if (error.response) {
                    console.log(error.response);
                  }
                this.setState( { loading: false, error : error } );
            } );
    }

    inputChangedHandler = (event, inputIdentifier) => {
        const updatedInsertForm = {
            ...this.state.insertForm
        };
        const updatedFormElement = { 
            ...updatedInsertForm[inputIdentifier]
        };
        updatedFormElement.value = event.target.value;
        updatedInsertForm[inputIdentifier] = updatedFormElement;
        this.setState({insertForm: updatedInsertForm});
    }

    updateInput = (event) => {
        this.setProps({editcontract : null});
        }
    
    change = (event) => {
        this.setState({company : event.target.value});
        }

    changetype = (event) => {
        this.setState({contracttype : event.target.value});
        }

    phaseChangedHandler = ( action ) => {
            switch ( action ) {
                case 'inc':
                    this.setState( ( prevState ) => { return { phase: prevState.phase + 1 } } )
                    break;
                case 'dec':
                    this.setState( ( prevState ) => { return { phase: prevState.phase - 1 } } )
                    break;
                default:
                    break;
            }
        }

    render () {
        console.log(this.props.ctype);
        const formElementsArray = [];
        for (let key in this.state.insertForm) {
            formElementsArray.push({
                id: key,
                config: this.state.insertForm[key]
            });
        }
        let form = (
            <div style={{ height: '250px', overflowY: 'scroll' }}>
            <form onSubmit={this.orderHandler}>           
                {formElementsArray.map(formElement => (
                    <Input 
                        key={formElement.id}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        changed={(event) => this.inputChangedHandler(event, formElement.id)} />
                ))}
                <button>INSERT</button>
                <button type="button" onClick={(event) => this.props.onEditChange(false)}>CANCEL</button>
            </form>
            </div>
        );
        if ( this.state.loading ) {
           form = <Spinner />;
        }
        if ( this.state.error ) {
            form = this.state.error.message;
         }
        return (
            <div>
                <h4>Insert contract details</h4>
                <h5>scroll down to submit</h5>
                {form}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        islogged: state.token !== null,
        token: state.token
    }
};

export default connect(mapStateToProps)(insertContract);