import React, { Component } from 'react';
import axios from 'axios';
import Spinner from '../../../components/UI/Spinner/Spinner';
import Input from '../../../components/UI/Input/Input';
import { connect } from 'react-redux';

class insertCompany extends Component {
    state = {
        insertForm: {
            name: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Company'
                },
                value: ''
            },
            description: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Description'
                },
                value: ''
            },
            vat_number: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'VAT Number'
                },
                value: ''
            },
            address: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Address'
                },
                value: ''
            },
            postal_code: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'ZIP Code'
                },
                value: ''
            },
            iban: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'IBAN'
                },
                value: ''
            },
            bic: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'BIC'
                },
                value: ''
            },
            company_enabled: {
                elementType: 'select',
                elementConfig: {
                    options: [
                        {value: '1', displayValue: 'Enabled'},
                        {value: '0', displayValue: 'Disabled'}
                    ]
                },
                value: '1'
            }
        },
        loading: false,
        company: this.props.company,
        error: false
    }

    orderHandler = ( event ) => {
        event.preventDefault();
        this.setState( { loading: true } );
        const formData = {};
        for (let formElementIdentifier in this.state.insertForm) {
            formData[formElementIdentifier] = this.state.insertForm[formElementIdentifier].value;
        }
        const order = {
            company: formData,
            token: this.props.token
        }
        
        axios.post( 'https://backend.24rent.fi/api2/company/insert', order)
            .then( response => {
                this.setState( { loading: false } );
                this.props.onEditChange(false)
               // this.props.history.push( '/' );
            } )
            .catch( error => {
                if (error.response) {
                    console.log(error.response);
                  }
                this.setState( { loading: false, error : error } );
            
            } );
    }

    inputChangedHandler = (event, inputIdentifier) => {
        const updatedInsertForm = {
            ...this.state.insertForm
        };
        const updatedFormElement = { 
            ...updatedInsertForm[inputIdentifier]
        };
        updatedFormElement.value = event.target.value;
        updatedInsertForm[inputIdentifier] = updatedFormElement;
        this.setState({insertForm: updatedInsertForm});
    }

    updateInput = (event) => {
   
        this.setProps({editcompany : null});
        }

    render () {
        const formElementsArray = [];
        for (let key in this.state.insertForm) {
            formElementsArray.push({
                id: key,
                config: this.state.insertForm[key]
            });
        }
        let form = (
            <form onSubmit={this.orderHandler}>           
                {formElementsArray.map(formElement => (
                    <Input 
                        key={formElement.id}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        changed={(event) => this.inputChangedHandler(event, formElement.id)} />
                ))}
                <button>INSERT</button>
                <button type="button" onClick={(event) => this.props.onEditChange(false)}>CANCEL</button>
            </form>
        );
        if ( this.state.loading ) {
           form = <Spinner />;
        }
        if ( this.state.error ) {
            form = this.state.error.message;
         }
        return (
            <div>
                <h4>Insert company Data</h4>
                {form}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        islogged: state.token !== null,
        token: state.token
    }
};

export default connect(mapStateToProps)(insertCompany);