import * as actionTypes from './actions';

const initialToken = localStorage.getItem('token')
  ? JSON.parse(localStorage.getItem('token'))
  : null

const initialState = {
    token: initialToken
};

const reducer = (state = initialState, action) => {
    switch ( action.type ) {
        case actionTypes.ADD_AUTHENTICATION:
            localStorage.setItem('token', JSON.stringify(action.token))
            return {
                ...state,
                token: action.token
            };
        default:
            return state;
    }
};

export default reducer;