import React, { Component } from 'react';
import axios from 'axios';

import Modal from '../../../components/UI/Modal/Modal';
import Editcompany from '../../../components/Company/EditCompany';
import InsertCompany from './InsertCompany.js';

class Editcompanies extends Component {

  state = {
    companies: [],
    editcompany: null,
    insertcompany: false
  }

  openmodal = company => {
    this.setState({ editcompany: company }); 
    if (company === null){
    
        const url = 'https://backend.24rent.fi/api2/company/getall'
        axios.get(url).then(response => response.data)
        .then((data) => {
          this.setState({ companies: data })
          console.log(this.state.companies)
         })

    }
  }

  openinsertmodal = company => {
    this.setState({ insertcompany: company }); 
    if (company === false){
    
        const url = 'https://backend.24rent.fi/api2/company/getall'
        axios.get(url).then(response => response.data)
        .then((data) => {
          this.setState({ companies: data })
          console.log(this.state.companies)
         })

    }
  }
  
  componentDidMount() {
    const url = 'https://backend.24rent.fi/api2/company/getall'
    axios.get(url).then(response => response.data)
    .then((data) => {
      this.setState({ companies: data })
      console.log(this.state.companies)
     })
  }

  editCancelledHandler = () => {
    this.setState({ editcompany: null }); 
    this.setState({ insertcompany: false }); 
    console.log('painettu cancel');
}

  render() {
    return (
        <React.Fragment>
        <h1>Customer Companies</h1><div>
        <button onClick={() => this.openinsertmodal(true)}>
            Insert new company
            </button>     
            <Modal show={this.state.insertcompany} modalClosed={this.editCancelledHandler}>
            <InsertCompany onEditChange={this.openinsertmodal}/>
            </Modal>  

        </div>
        <table>
        <tbody>
        <tr>
            <th>Name</th>
            <th>Description</th>
            <th>VAT</th>
            <th>Address</th>
            <th>Postal Code</th>
            <th>Municipality id</th>
            <th>IBAN</th>
            <th>BIC</th>
            <th>Created</th>
            <th>Updated</th>
            <th>Enabled</th>
            <th></th>          
        </tr>

        {this.state.companies.map((company) => (
        <tr key={company.id}>
            <td>{ company.name }</td>
            <td>{ company.description }</td>
            <td>{ company.vat_number }</td>
            <td>{ company.address }</td>
            <td>{ company.postal_code }</td>
            <td>{ company.municipality_id }</td>
            <td>{ company.iban }</td>
            <td>{ company.bic }</td>
            <td>{ company.created_at }</td>
            <td>{ company.updated_at }</td>
            <td>{ company.company_enabled }</td>
            <td>
            <button onClick={() => this.openmodal(company.id)}>
            Edit
            </button>     
            <Modal show={company.id === this.state.editcompany} modalClosed={this.editCancelledHandler}>
            <Editcompany company={company} editcompany={this.state.editcompany} onEditChange={this.openmodal}/>
            </Modal>  
            </td>
        </tr>
        ))}
        </tbody>
        </table>
        </React.Fragment>
    );
  }

}

export default Editcompanies;