import React, { Component } from 'react';
import axios from 'axios';

import Modal from '../../../components/UI/Modal/Modal';
import Editcontract from './EditContract';
import InsertContract from './InsertContract';
import ActivateContract from './ActivateContract';

class Editcontracts extends Component {

  state = {
    contracts: [],
    editcontract: null,
    insertcontract: false,
    activatecontract: false
  }

  openmodal = contract => {
    this.setState({ editcontract: contract }); 
    if (contract === null){
        const url = 'https://backend.24rent.fi/api2/contract/getall'
        axios.get(url).then(response => response.data)
        .then((data) => {
          console.log(this.state.contracts);
          this.setState({ contracts: data });
         })
    }
  }

  openinsertmodal = currcontract => {
    this.setState({ insertcontract: currcontract }); 
    if (currcontract === false){
        const url = 'https://backend.24rent.fi/api2/contract/getall'
        axios.get(url).then(response => response.data)
        .then((data) => {
          this.setState({ contracts: data })
          console.log(this.state.contracts)
         })

    }
  }

  openactivatemodal = currcontract => {
    this.setState({ activatecontract: currcontract }); 
    if (currcontract === false){
        const url = 'https://backend.24rent.fi/api2/contract/getall'
        axios.get(url).then(response => response.data)
        .then((data) => {
          this.setState({ contracts: data })
          console.log(this.state.contracts)
         })

    }
  }
  
  componentDidMount() {
    const url = 'https://backend.24rent.fi/api2/contract/getall'
    axios.get(url).then(response => response.data)
    .then((data) => {
      this.setState({ contracts: data })
      console.log(this.state.contracts)
     })
  }

  editCancelledHandler = () => {
    this.setState({ editcontract: null }); 
    this.setState({ insertcontract: false }); 
    this.setState({ activatecontract: false }); 
    console.log('painettu cancel');
}

  render() {
    return (
        <React.Fragment>
        <div>
        <h1>Contracts</h1></div>
        <table>
        <tbody>
        <tr>
        <th>
        <button onClick={() => this.openinsertmodal(true)}>
            Insert new contract
            </button>       
            <Modal show={this.state.insertcontract} modalClosed={this.editCancelledHandler}>
            <InsertContract onEditChange={this.openinsertmodal} ctype={null}/>
            </Modal>  
        </th>
        </tr>
        <tr>
        <th>
        <button onClick={() => this.openactivatemodal(true)}>
            Activate contract
            </button>  
            <Modal show={this.state.activatecontract} modalClosed={this.editCancelledHandler}>
            <ActivateContract onEditChange={this.openactivatemodal}/>
            </Modal> 
        </th> 
        </tr>
        </tbody>
        </table>

        <table>
        <tbody>
        <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Startdate</th>
            <th>Enddate</th>
            <th>Type</th>
            <th>Created</th>
            <th>Updated</th>
            <th>User</th>
            <th>Cost Monthly</th>
            <th>Cost initial</th>
            <th>Income monthly</th>
            <th>Income initial</th>
            <th>Metabase URL</th>
            <th>Metabase public URL</th>
            <th>Max parking slots</th>
            <th>Enabled</th>
            <th></th>      
            </tr>

        {this.state.contracts.map((contract) => (
        <tr key={contract.id}>
            <td>{ contract.name }</td>
            <td>{ contract.description }</td>
            <td>{ contract.contract_startdate }</td>
            <td>{ contract.contract_enddate }</td>
            <td>{ contract.id_contract_type }</td>
            <td>{ contract.created_at }</td>
            <td>{ contract.updated_at }</td>
            <td>{ contract.id_user }</td>
            <td>{ contract.cost_monthly }</td>
            <td>{ contract.cost_initial }</td>
            <td>{ contract.income_monthly }</td>
            <td>{ contract.income_initial }</td>
            <td>{ contract.metabase_url }</td>
            <td>{ contract.metabase_public_url }</td>
            <td>{ contract.max_parking_slots }</td>
            <td>{ contract.enabled }</td>
            <td> 
            <button onClick={() => this.openmodal(contract.id)}>
              Edit
              </button>     
            <Modal show={contract.id === this.state.editcontract} modalClosed={this.editCancelledHandler}>
            <Editcontract contract={contract} editcontract={this.state.editcontract} onEditChange={this.openmodal}/>
            </Modal>   
            </td>
        </tr>
        ))}
        </tbody>
        </table>
        </React.Fragment>
    );
  }

}

export default Editcontracts;