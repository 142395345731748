import React, { Component } from 'react';
// import axios from 'axios';
import { Route, Switch, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';

import Companies from './Companies/Companies';
import Editcompanies from './Companies/Editcompanies';
import Contracts from './Contracts/Contracts';
import Editcontracts from './Contracts/Editcontracts';
import Login from './Login/Login';
import Navigation from './Navigation';

import Auth from '../Auth/Auth';

class Admin extends Component {
    render () {
        let nav = <Redirect to="/notauth" />;
        if (this.props.islogged){nav = <Navigation />}

        return (
            <div>
                <header>
                    {nav}
                </header>
                <Switch>
                <Route path="/contracts" exact component={Contracts} />
                <Route path="/editcontracts" exact component={Editcontracts} />                 
                <Route path="/companies" exact component={Companies} />
                <Route path="/editcompanies" exact component={Editcompanies} />                 
                <Route path="/token/:token" exact component={Auth} />
                <Route path="/notauth" exact component={Login} />
                </Switch>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        islogged: state.token !== null,
        token: state.token
    }
};

export default connect(mapStateToProps)(Admin);
