import React from 'react';
import { Link } from 'react-router-dom';

const navigation = () => (
    
    <nav>
    <ul>
        <li  style={{ display: 'inline-block', padding: '20px', background: '#dddddd' }}><Link to="/companies">Companies</Link></li>
        <li  style={{ display: 'inline-block', padding: '20px', background: '#dddddd'  }}><Link to="/editcompanies">Edit Companies</Link></li>
        <li  style={{ display: 'inline-block', padding: '20px', background: '#dddddd'  }}><Link to="/contracts">Contracts</Link></li>
        <li  style={{ display: 'inline-block', padding: '20px', background: '#dddddd'  }}><Link to="/editcontracts">Edit Contracts</Link></li>
    </ul>
    </nav>
);

export default navigation;