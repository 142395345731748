import React, { Component } from 'react';
import { connect } from 'react-redux';
// import axios from 'axios';
import { Redirect } from 'react-router-dom';
import * as actionTypes from '../../store/actions';


class Auth extends Component {
    state = {
        logged: false
    }

    componentDidMount () {
        console.log(this.props);
        this.Authenticate();
    }

    Authenticate () {
        if ( this.props.match.params.token ) {
            this.props.onAuth(this.props.match.params.token);
            if ( this.props.match.params.token === 'testtoken123')  {
   
                        this.setState( { logged: true } );
                    } 
            }
        }

        render () {
            let post = <p style={{ textAlign: 'center' }}>Please authenticate!</p>;
            if ( this.state.logged ) {
                post = (
                    <div>
                        <h1>logged in</h1>
                    </div>
    
                );
            }
            post = <Redirect to="/" />;
            return post;
        }


}

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (token) => dispatch({type: actionTypes.ADD_AUTHENTICATION, token: token})
    };
};

export default connect(null, mapDispatchToProps)(Auth);