import React, { Component } from 'react';

class Login extends Component {

  render() {
    return (
        <React.Fragment>
    <div>
        This app is only for authenticated users, please login to admin first.
    </div>
        </React.Fragment>
    );
  }

}

export default Login;